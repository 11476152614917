.IamPanel {
    composes: card from global;
    width: 20em;
    opacity: 1;
    transition: all 0.2s;

    view-transition-name: IamPanel;
    
    h3 {
        view-transition-name: IamHeading;
    }
    a {
        align-self: flex-end;
        &:last-child {
            view-transition-name: IamLink;
        }
    }

    input[type=email] {
        view-transition-name: IamEmail;
    }

    &[data-busy="true"] {
        /* opacity: 0.5; */
        pointer-events: none;
    }

    button {
        view-transition-name: IamButton;
    }

    label:has(input[type="checkbox"]) {
        position: relative;
        display: flex;
        align-items: center;
        input {
            width: 100%
        }
        input[type="checkbox"] {
            cursor: pointer;
            color: var(--sovereign-blue-dark);
            opacity: 0.5;
            position: absolute;
            appearance: none;
            -webkit-appearance: none;
            background-color: transparent;
            border: none;
            margin: 0;
            padding: 0;
            right: 0;
            height: 2em;
            width: 2em;
            display: flex;
            align-items: center;
            justify-content: center;
            &::before {
                padding-right: 0.5em;
                text-align: center;
                background-color: transparent;
                display: block;
                content: "\f06e";
                font-family: FontAwesome;
                font-size: 1.5rem;
            }
            &:checked::before {
                content: "\f070";
            }
        }
    }
}

::view-transition-old(IamPanel),
::view-transition-new(IamPanel) {
    height: 100%;
    mix-blend-mode: normal;
    overflow: clip;
}

[data-modal-pane]:has(.IamPanel) {
    background-color: rgb(0, 0, 0, 0.5);
}


.spinner {
    background-color: rgb(256, 0, 0, 0.2);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

