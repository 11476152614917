.Modal {
    background-color: white;
    border-radius: 0.5em;
    position: relative;
    max-height: 70vh;
    border: 1px solid var(--border-color);
}
.Modal section {
    padding: 2em 2em 1em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1em;
}
.Modal h3, p {
    cursor: default;
}