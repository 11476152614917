
.padding-standard {
    padding: 1em;
}

.card {
    border-radius: var(--border-radius-large);
    background-color: var(--card-background-color);
    padding: var(--padding-large);
    display: flex;
    flex-direction: column;
    gap: var(--padding-large);
}