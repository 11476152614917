.Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10em;   
}

.Buttons {
    display: flex;
    gap: 1em;
}