.ClaimAssessmentForm {
    composes: card from global;
    height: 100%;
    width: 100%;
}

.AddItemButton {
    min-height: initial;
}

.DeleteItemButton {
    cursor: pointer;
}

.ClaimAssessmentTable {
    & th {
        text-align: left;
    }

    & td {
        padding: 0.4em 1em 0.4em 0;
    }

    & input, select {
        height: 2.5em;
        width: 100%;
    }

    & .ColumnCost {
        width: 8em;

        & input {
            text-align: right;
        }
    }

    & .ColumnBenefit {
        width: 15em;
    }

    & .ColumnDate {
        width: 10em;
    }

    & .ColumnLocation {
        width: 10em;
    }

    & .ColumnDecision {
        width: 15em;
    }

    & .ColumnRemove {
        width: 2em;
    }
}

