.CreateUser {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 24em;
    margin-inline: auto;
}

.Error {
    color: red;
}

.UserCreated {
    list-style: none;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
}