.Claim {
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    /* TODO: we should use this as the max width / dimensions for most of the pages */
    max-width: 1440px;
    min-width: 768px;
    width: 90vw;
    margin: 0 auto;
}

.SectionTitle {
    border-bottom: 1px solid var(--blue-grey);
    padding-bottom: 0.5em;
}

.ClaimOverview {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: 50px 150px 150px;
    grid-template-areas:
      " status        view-customer"
      " reference     intercom "
      " plan-details  intercom ";

    > * {
        align-content: center;
    }

    > .Status {
        grid-area: status;  
    }

    > .ViewCustomer {
        grid-area: view-customer;
    }

    > .Reference {
        grid-area: reference;
    }

    > .Intercom {
        grid-area: intercom;
    }

    > .PlanDetails {
        grid-area: plan-details;
    }
}

.ClaimDetails {
    display: flex;
    flex-direction: column;
}