.ListTable {
    font-size: 0.9em;
    border-collapse: separate;
    border-spacing: 0 8px;
    width: 100%;
}

.Header > th {
    font-weight: normal;
    text-align: left;
}

.Header > :first-child,
.ListTable tbody > tr :first-child {
    border-top-left-radius: var(--table-border-radius);
    border-bottom-left-radius: var(--table-border-radius);
}

.Header > :last-child,
.ListTable tbody > tr :last-child {
    border-top-right-radius: var(--table-border-radius);
    border-bottom-right-radius: var(--table-border-radius);
}

.Header > th,
.ListTable td {
    padding: 1.2em;
    align-items: center;
    align-content: center;
    background-color: var(--table-background-color);
}

.ListTable[data-display-hover="true"] tbody > tr:hover > * {
    cursor: pointer;
    background-color: var(--table-hover-color);
}