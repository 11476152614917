.ListTable {
    --border: 2px solid;

    border-spacing: 0; 
    border-collapse: separate;
    margin-bottom: 8em;

    & td {
        padding: 0.7em 1.2em;
    }
}

.SingleLife {
    & > :first-child {
        border-top-left-radius: var(--table-border-radius);
        border-bottom-left-radius: var(--table-border-radius);
        border-left: var(--border) var(--table-border-color);
    }	
        
    & > :last-child {
        border-top-right-radius: var(--table-border-radius);
        border-bottom-right-radius: var(--table-border-radius);
        border-right: var(--border) var(--table-border-color);
    }

    & td {
        border-top: var(--border) var(--table-border-color);
        border-bottom: var(--border) var(--table-border-color);
    }

}

.MultiLife {
    &.FirstLife {
        & td:first-child {
            border-top-left-radius: var(--table-border-radius);
            border-bottom-left-radius: 0;
            border-left: var(--border) var(--table-border-color);
        }
        & td:last-child {
            border-top-right-radius: var(--table-border-radius);
            border-bottom-right-radius: 0;
            border-right: var(--border) var(--table-border-color);
        }

        & td {
            border-top: var(--border) var(--table-border-color);
        }
    }

    &.LastLife {
        & td:first-child {
            border-bottom-left-radius: var(--table-border-radius);
            border-top-left-radius: 0;
            border-left: var(--border) var(--table-border-color);
        }
        & td:last-child {
            border-bottom-right-radius: var(--table-border-radius);
            border-top-right-radius: 0;
            border-right: var(--border) var(--table-border-color);
        }
        & td {
            border-bottom: var(--border) var(--table-border-color);
        }
    }
}

.RowGap {
    visibility: hidden;
	padding: 5px !important;
}

/* Row highlighting */

.HighlightNew {
    --table-border-color: var(--sovereign-goto-turquoise);

    & td {
        background-color: var(--sovereign-goto-turquoise-light);
    }
}

.HighlightUpdated {
    --table-border-color: var(--sovereign-goto-turquoise);
}

.HighlightRemoved {
    composes: HighlightUpdated;
    color: grey;
}
