:root {
    --card-gap: 2em;
    --card-gap-group-label: 3em;
}

.cardContainer {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: var(--card-gap);
}

.card>* {
    height: 5em;
}

.benefitOptions {
    padding: 0 20px;
}

.title {
    font-weight: 700;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > a {
        font-size: 12px;
        text-decoration: underline;
        color: black;
    }
}

.groupLabel {
    height: var(--card-gap-group-label);
    align-items: start;
    font-weight: 700;
    font-size: 1.1em;
    padding-top: calc(var(--card-gap-group-label) - var(--card-gap));
}

.benefitsContainer {
    display: flex;
    flex-flow: column;
    row-gap: var(--card-gap-group-label);
}

.planCardsContainer {
    display: flex;
    flex-flow: row;
    column-gap: var(--card-gap);
}

.globalBenefitsContainer {
    display: grid;
    row-gap: var(--card-gap);
}